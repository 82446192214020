import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';

export const sendFeedback = createAsyncThunk(
  '/user/sendFeedback',
  async (data) => {
    const res = await request({ method: 'post', url: '/feedback', data });
    return res.data;
  },
);

export const getAdvices = createAsyncThunk(
  '/user/getAdvices',
  async (context) => {
    const res = await request({ method: 'get', url: `/advices/${context}` });
    return res.data;
  },
);

export const getImage = createAsyncThunk(
  '/images',
  async (imgPath) => {
    const res = await request({ method: 'get', url: `/images/${imgPath}` });
    return res.data;
  },
);
