import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAMES, DEFAULT_STATES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const notificationsSlice = createSlice({
  name: SLICE_NAMES.USER,
  initialState: { ...DEFAULT_STATES.NOTIFICATIONS },
  reducers: {
    clearNotifications: () => DEFAULT_STATES.NOTIFICATIONS,
  },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.NOTIFICATIONS].actionResult);
export const notificationsSelector = createCustomSelector((state) => state[SLICE_NAMES.NOTIFICATIONS].notifications);
export const newNotifyCountSelector = createCustomSelector((state) => state[SLICE_NAMES.NOTIFICATIONS].newNotifyCount);

export const {
  clearNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
