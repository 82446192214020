import { getNotifications, sendNotification, getNewNotifyCount } from '@redux/notifications/thunks';

const thunksConfig = [
  {
    type: 'getNotifications',
    thunk: getNotifications,
    callbacks: {
      fulfilled: (state, action) => {
        state.notifications = action.payload;
      },
    },
  },
  {
    type: 'sendNotification',
    thunk: sendNotification,
    callbacks: {
      fulfilled: (state, action) => {},
    },
  },
  {
    type: 'getNewNotifyCount',
    thunk: getNewNotifyCount,
    callbacks: {
      fulfilled: (state, action) => {
        state.newNotifyCount = action.payload;
      },
    },
  },
];

export default thunksConfig;
