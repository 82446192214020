import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAMES, DEFAULT_STATES, DEFAULT_ACTION_RESULT } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const smileSlice = createSlice({
  name: SLICE_NAMES.SMILE,
  initialState: { ...DEFAULT_STATES.SMILE },
  reducers: {
    setFile(state, action) {
      state.file = action.payload;
    },
    resetActionResult(state) {
      state.actionResult = DEFAULT_ACTION_RESULT;
    },
  },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.SMILE].actionResult);
export const activitiesSelector = createCustomSelector((state) => state[SLICE_NAMES.SMILE].activities);
export const smileAmountSelector = createCustomSelector((state) => state[SLICE_NAMES.SMILE].amount);
export const fileSelector = createCustomSelector((state) => state[SLICE_NAMES.SMILE].file);

export const {
  setFile,
  resetActionResult,
} = smileSlice.actions;

export default smileSlice.reducer;
