import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAMES, DEFAULT_STATES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import { LOCAL_STORAGE_KEYS } from '@constants/storage';
import thunksConfig from './thunksConfig';

export const authSlice = createSlice({
  name: SLICE_NAMES.AUTH,
  initialState: { ...DEFAULT_STATES.AUTH },
  reducers: {
    setToken(state, action) {
      if (!action.payload) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
        state.user = {};
      }
      state.token = action.payload;
    },
    clearMeState: () => DEFAULT_STATES.AUTH,
  },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.AUTH].actionResult);
export const tokenSelector = createCustomSelector((state) => state[SLICE_NAMES.AUTH].token);
export const userSelector = createCustomSelector((state) => state[SLICE_NAMES.AUTH].user);

export const {
  setToken,
  clearMeState,
} = authSlice.actions;

export default authSlice.reducer;
