import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAMES, DEFAULT_STATES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const dashboardSlice = createSlice({
  name: SLICE_NAMES.DASHBOARD,
  initialState: { ...DEFAULT_STATES.DASHBOARD },
  reducers: {
    clearDashboard: () => DEFAULT_STATES.DASHBOARD,
  },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.DASHBOARD].actionResult);
export const levelsSelector = createCustomSelector((state) => state[SLICE_NAMES.DASHBOARD].levels);

export const {
  clearDashboard,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
