import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAMES, DEFAULT_STATES, DEFAULT_ACTION_RESULT } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const userSlice = createSlice({
  name: SLICE_NAMES.USER,
  initialState: { ...DEFAULT_STATES.USER },
  reducers: {
    clearUserInfo: () => DEFAULT_ACTION_RESULT.USER,
  },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.USER].actionResult);
export const advicesSelector = createCustomSelector((state) => state[SLICE_NAMES.USER].advices);

export const {
  clearUserInfo,
} = userSlice.actions;

export default userSlice.reducer;
