import {
  sendFeedback,
  getAdvices,
} from '@redux/user/thunks';

const thunksConfig = [
  {
    type: 'sendFeedback',
    thunk: sendFeedback,
    callbacks: {
      fulfilled: (state, action) => {},
    },
  },
  {
    type: 'getAdvices',
    thunk: getAdvices,
    callbacks: {
      fulfilled: (state, action) => {
        state.advices = action.payload;
      },
    },
  },
];

export default thunksConfig;
