import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import { ROUTES, PATHS, getScopeWrapper } from '@constants';
import { tokenSelector } from '@redux/auth';

const Routers = () => {
  const token = useSelector(tokenSelector);

  return (
    <Switch>
      {
        ROUTES.map(({
          path,
          exact,
          scope,
          component: Component,
        }) => {
          const RouteRenderer = getScopeWrapper(scope);
          return (
            <RouteRenderer
              key={path}
              token={token}
              exact={exact}
              path={path}
              component={Component}
            />
          );
        })
      }
      <Redirect to={PATHS.NOT_FOUND} />
    </Switch>
  );
};

export default Routers;
