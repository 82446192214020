import { LOCAL_STORAGE_KEYS } from '@constants';
import {
  login,
  register,
  getUserInfo,
  changePassword,
  resetPassword,
  deleteAccount,
  updateProfile,
} from '@redux/auth/thunks';

const thunksConfig = [
  {
    type: 'login',
    thunk: login,
    callbacks: {
      fulfilled: (state, action) => {
        state.token = action.payload.access_token;
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, action.payload.access_token);
      },
    },
  },
  {
    type: 'register',
    thunk: register,
    callbacks: {
      fulfilled: (state, action) => {
        state.user = action.payload;
      },
    },
  },
  {
    type: 'updateProfile',
    thunk: updateProfile,
    callbacks: {
      fulfilled: (state, action) => {},
    },
  },
  {
    type: 'deleteAccount',
    thunk: deleteAccount,
    callbacks: {
      fulfilled: (state, action) => {},
    },
  },
  {
    type: 'getUserInfo',
    thunk: getUserInfo,
    callbacks: {
      fulfilled: (state, action) => {
        state.user = action.payload;
      },
    },
  },
  {
    type: 'changePassword',
    thunk: changePassword,
    callbacks: {
      fulfilled: (state, action) => { },
    },
  },
  {
    type: 'resetPassword',
    thunk: resetPassword,
    callbacks: {
      fulfilled: (state, action) => { },
    },
  },
];

export default thunksConfig;
