import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';

export const login = createAsyncThunk(
  '/auth/login',
  async (data) => {
    const res = await request({ method: 'post', url: '/auth/getToken', data });
    return res.data;
  },
);

export const register = createAsyncThunk(
  '/auth/register',
  async (data) => {
    const res = await request({ method: 'post', url: '/auth/register', data });
    return res.data;
  },
);

export const updateProfile = createAsyncThunk(
  '/auth/updateProfile',
  async (data) => {
    const res = await request({ method: 'patch', url: '/user', data });
    return res.data;
  },
);

export const deleteAccount = createAsyncThunk(
  '/auth/deleteAccount',
  async (data) => {
    const res = await request({ method: 'delete', url: '/auth', data });
    return res.data;
  },
);

export const getUserInfo = createAsyncThunk(
  '/auth/getUserInfo',
  async () => {
    const res = await request({ method: 'get', url: '/auth' });
    return res.data;
  },
);

export const changePassword = createAsyncThunk(
  '/auth/changePassword',
  async (data) => {
    const res = await request({ method: 'patch', url: '/auth/changePassword', data });
    return res.data;
  },
);

export const resetPassword = createAsyncThunk(
  '/auth/resetPassword',
  async (data) => {
    const res = await request({ method: 'patch', url: '/auth/resetPassword', data });
    return res.data;
  },
);
