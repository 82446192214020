import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAMES, DEFAULT_STATES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const mySpaceSlice = createSlice({
  name: SLICE_NAMES.MY_SPACE,
  initialState: { ...DEFAULT_STATES.MY_SPACE },
  reducers: {
    resetCurrentArticle(state) {
      state.currentArticle = {};
    },
    resetComments(state) {
      state.comments = [];
    },
    setCurrentCommentsNodeId(state, action) {
      state.currentCommentsNodeId = action.payload;
    },
    resetCurrentCommentsNodeId(state) {
      state.currentCommentsNodeId = '';
    },
  },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.MY_SPACE].actionResult);
export const collectionSelector = createCustomSelector((state) => state[SLICE_NAMES.MY_SPACE].collection);
export const articlesSelector = createCustomSelector((state) => state[SLICE_NAMES.MY_SPACE].articles);
export const gallerySelector = createCustomSelector((state) => state[SLICE_NAMES.MY_SPACE].gallery);
export const currentArticleSelector = createCustomSelector((state) => state[SLICE_NAMES.MY_SPACE].currentArticle);
export const commentsSelector = createCustomSelector((state) => state[SLICE_NAMES.MY_SPACE].comments);
export const currentCommentsNodeIdSelector = createCustomSelector((state) => state[SLICE_NAMES.MY_SPACE].currentCommentsNodeId);
export const articlesCategoriesSelector = createCustomSelector((state) => state[SLICE_NAMES.MY_SPACE].categories);

export const {
  resetCurrentSpaceItem,
  resetComments,
  setCurrentCommentsNodeId,
  resetCurrentCommentsNodeId,
} = mySpaceSlice.actions;

export default mySpaceSlice.reducer;
