import { withLazyLoad } from '@hocs';
import { PATHS } from './paths';
import { ROUTE_SCOPES } from './scopes';

const { AUTH, NO_AUTH } = ROUTE_SCOPES;

const SignInScreen = withLazyLoad(() => import('@screens/NoAuth/SignIn'));
const SignUpScreen = withLazyLoad(() => import('@screens/NoAuth/SignUp'));
const ForgotPasswordScreen = withLazyLoad(() => import('@screens/NoAuth/ForgotPass'));
const CreateAccountScreen = withLazyLoad(() => import('@screens/NoAuth/CreateAccount'));
const HomeScreen = withLazyLoad(() => import('@screens/Common/Home'));
const MySpaceScreen = withLazyLoad(() => import('@screens/Common/MySpace'));
const MySpaceInnerScreen = withLazyLoad(() => import('@screens/Common/MySpaceInner'));
const MakeSmileScreen = withLazyLoad(() => import('@screens/Common/MakeSmile'));
const StudiesScreen = withLazyLoad(() => import('@screens/Common/Studies'));
const SurveyScreen = withLazyLoad(() => import('@screens/Common/Survey'));
const DedicateScreen = withLazyLoad(() => import('@screens/Common/Dedicate'));
const DiagnoseScreen = withLazyLoad(() => import('@screens/Common/Diagnose'));
const TreatmentScreen = withLazyLoad(() => import('@screens/Common/Treatment'));
const TreatmentMedicationScreen = withLazyLoad(() => import('@screens/Common/TreatmentMedication'));
const ClinicsScreen = withLazyLoad(() => import('@screens/Common/Clinics'));
const AdvisorScreen = withLazyLoad(() => import('@screens/Common/Advisor'));
const AdvisorRecommendationsScreen = withLazyLoad(() => import('@screens/Common/AdvisorRecommendations'));
const NotificationsScreen = withLazyLoad(() => import('@screens/Auth/Notifications'));
const ProfileScreen = withLazyLoad(() => import('@screens/Auth/Profile'));
const DashboardScreen = withLazyLoad(() => import('@screens/Auth/Dashboard'));
const PrivacyPolicyScreen = withLazyLoad(() => import('@screens/Common/PrivacyPolicy'));
const TermsScreen = withLazyLoad(() => import('@screens/Common/Terms'));
const NotFoundScreen = withLazyLoad(() => import('@screens/Common/NotFound'));

export const ROUTES = [
  {
    exact: true,
    path: PATHS.SIGN_IN,
    component: SignInScreen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.SIGN_UP,
    component: SignUpScreen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.FORGOT_PASS,
    component: ForgotPasswordScreen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.CREATE_ACCOUNT,
    component: CreateAccountScreen,
    scope: NO_AUTH,
  },
  { exact: true, path: PATHS.HOME, component: HomeScreen },
  { exact: true, path: PATHS.MY_SPACE, component: MySpaceScreen },
  { exact: true, path: PATHS.MY_SPACE_INNER(), component: MySpaceInnerScreen },
  { exact: true, path: PATHS.MAKE_SMILE, component: MakeSmileScreen },
  { exact: true, path: PATHS.STUDIES, component: StudiesScreen },
  { exact: true, path: PATHS.SURVEY(), component: SurveyScreen },
  { exact: true, path: PATHS.DEDICATE(), component: DedicateScreen },
  { exact: true, path: PATHS.DIAGNOSE(), component: DiagnoseScreen },
  { exact: true, path: PATHS.TREATMENT(), component: TreatmentScreen },
  { exact: true, path: PATHS.TREATMENT_MEDICATION(), component: TreatmentMedicationScreen },
  { exact: true, path: PATHS.CLINICS, component: ClinicsScreen },
  { exact: true, path: PATHS.ADVISOR, component: AdvisorScreen },
  { exact: true, path: PATHS.ADVISOR_RECOMMENDATIONS, component: AdvisorRecommendationsScreen },
  { exact: true, path: PATHS.PRIVACY_POLICY, component: PrivacyPolicyScreen },
  { exact: true, path: PATHS.TERMS, component: TermsScreen },
  {
    exact: true,
    path: PATHS.NOTIFICATIONS,
    component: NotificationsScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.PROFILE,
    component: ProfileScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.DASHBOARD,
    component: DashboardScreen,
    scope: AUTH,
  },
  { path: PATHS.NOT_FOUND, component: NotFoundScreen },
];
