import {
  getAllSurveys,
  getSurveyInfo,
  getSurvey,
  saveSurveyAnswers,
  getFinishedSurveysCount,
  getExperienceAsYou,
} from '@redux/survey/thunks';

const thunksConfig = [
  {
    type: 'getAllSurveys',
    thunk: getAllSurveys,
    callbacks: {
      fulfilled: (state, action) => {
        state.surveys = action.payload;
      },
    },
  },
  {
    type: 'getSurveyInfo',
    thunk: getSurveyInfo,
    callbacks: {
      fulfilled: (state, action) => {
        state.currentSurveyInfo = action.payload;
      },
    },
  },
  {
    type: 'getSurvey',
    thunk: getSurvey,
    callbacks: {
      fulfilled: (state, action) => {
        state.currentSurvey = action.payload;
      },
    },
  },
  {
    type: 'saveSurveyAnswers',
    thunk: saveSurveyAnswers,
    callbacks: {
      fulfilled: (state, action) => {
        state.currentSurveyResult = action.payload;
      },
    },
  },
  {
    type: 'getFinishedSurveysCount',
    thunk: getFinishedSurveysCount,
    callbacks: {
      fulfilled: (state, action) => {
        state.finished = action.payload?.count;
      },
    },
  },
  {
    type: 'getExperienceAsYou',
    thunk: getExperienceAsYou,
    callbacks: {
      fulfilled: (state, action) => {
        state.experienceAsYou = action.payload;
      },
    },
  },
];

export default thunksConfig;
