import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAMES, DEFAULT_STATES, DEFAULT_ACTION_RESULT } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const surveySlice = createSlice({
  name: SLICE_NAMES.SURVEY,
  initialState: { ...DEFAULT_STATES.SURVEY },
  reducers: {
    resetSurveys(state) {
      state.surveys = [];
    },
    resetCurrentSurvey(state) {
      state.currentSurvey = {};
    },
    resetActionResult(state) {
      state.actionResult = DEFAULT_ACTION_RESULT;
    },
  },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.SURVEY].actionResult);
export const surveysSelector = createCustomSelector((state) => state[SLICE_NAMES.SURVEY].surveys);
export const currentSurveySelector = createCustomSelector((state) => state[SLICE_NAMES.SURVEY].currentSurvey);
export const currentSurveyInfoSelector = createCustomSelector((state) => state[SLICE_NAMES.SURVEY].currentSurveyInfo);
export const finishedSurveysCountSelector = createCustomSelector((state) => state[SLICE_NAMES.SURVEY].finished);
export const experienceAsYouSelector = createCustomSelector((state) => state[SLICE_NAMES.SURVEY].experienceAsYou);
export const currentSurveyResultSelector = createCustomSelector((state) => state[SLICE_NAMES.SURVEY].currentSurveyResult);

export const {
  resetSurveys,
  resetCurrentSurvey,
  resetActionResult,
} = surveySlice.actions;

export default surveySlice.reducer;
