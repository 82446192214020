import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PATHS } from '@constants';
import { useRouter } from '@hooks';

const NoAuthRouters = ({ component: Component, token, ...rest }) => {
  const { location } = useRouter();
  const backURL = location.state ? location.state.from.pathname : PATHS.HOME;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          return <Redirect to={backURL} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default NoAuthRouters;
