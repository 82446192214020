import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';

export const getCountries = createAsyncThunk(
  'dictionaries/getCountries',
  async () => {
    const countriesResponse = await request({ method: 'get', url: '/countries' });
    return countriesResponse.data;
  },
);

export const getDiagnoses = createAsyncThunk(
  'dictionaries/getDiagnoses',
  async () => {
    const diagnosisResponse = await request({ method: 'get', url: '/cancer-diagnoses' });
    return diagnosisResponse.data;
  },
);

export const getTreatment = createAsyncThunk(
  'dictionaries/getTreatment',
  async (params) => {
    const treatmentResponse = await request({ method: 'get', url: '/treatment', params });
    return treatmentResponse.data;
  },
);

export const getSideEffectTreatments = createAsyncThunk(
  'dictionaries/getSideEffectTreatments',
  async () => {
    const treatmentsResponse = await request({ method: 'get', url: '/side-effect/treatments' });
    return treatmentsResponse.data;
  },
);

export const getAgeGroups = createAsyncThunk(
  'dictionaries/getAgeGroups',
  async () => {
    const diagnosisResponse = await request({ method: 'get', url: '/age-groups' });
    return diagnosisResponse.data;
  },
);

export const getConditions = createAsyncThunk(
  'dictionaries/getConditions',
  async () => {
    const diagnosisResponse = await request({ method: 'get', url: '/current-conditions' });
    return diagnosisResponse.data;
  },
);

export const getClinics = createAsyncThunk(
  'dictionaries/getClinics',
  async () => {
    const diagnosisResponse = await request({ method: 'get', url: '/clinics' });
    return diagnosisResponse.data;
  },
);
