import { LOCAL_STORAGE_KEYS } from '@constants';
import {
  getSymptoms,
  getSymptomDetails,
  getIntro,
} from '@redux/advisor/thunks';

const thunksConfig = [
  {
    type: 'getSymptoms',
    thunk: getSymptoms,
    callbacks: {
      fulfilled: (state, action) => {
        state.symptoms = action.payload;
      },
    },
  },
  {
    type: 'getSymptomDetails',
    thunk: getSymptomDetails,
    callbacks: {
      fulfilled: (state, action) => {
        state.symptomDetails = action.payload;
        localStorage.setItem(LOCAL_STORAGE_KEYS.SYMPTOM_RECOMMENDATIONS, JSON.stringify(action.payload));
      },
    },
  },
  {
    type: 'getIntro',
    thunk: getIntro,
    callbacks: {
      fulfilled: (state, action) => {
        state.intro = action.payload;
      },
    },
  },
];

export default thunksConfig;
