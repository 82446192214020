import { configureStore } from '@reduxjs/toolkit';
import { tokenMiddleware } from './middlewares';
import initReducer from './init';
import authReducer from './auth';
import userReducer from './user';
import homeReducer from './home';
import notificationsReducer from './notifications';
import MySpaceReducer from './mySpace';
import surveyReducer from './survey';
import smileReducer from './smile';
import dictionariesReducer from './dictionaries';
import dashboardReducer from './dashboard';
import advisorReducer from './advisor';

export default configureStore({
  reducer: {
    init: initReducer,
    auth: authReducer,
    user: userReducer,
    home: homeReducer,
    notifications: notificationsReducer,
    mySpace: MySpaceReducer,
    survey: surveyReducer,
    smile: smileReducer,
    dictionaries: dictionariesReducer,
    dashboard: dashboardReducer,
    advisor: advisorReducer,
  },
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware().concat(tokenMiddleware)
  ),
});
