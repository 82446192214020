import { getHomeData } from '@redux/home/thunks';

const thunksConfig = [
  {
    type: 'getHomeData',
    thunk: getHomeData,
    callbacks: {
      fulfilled: (state, action) => {
        state.data = action.payload;
      },
    },
  },
];

export default thunksConfig;
