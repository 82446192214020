import { LOCAL_STORAGE_KEYS } from '@constants/storage';
import { message } from 'antd';
import axios from './axiosApi';

export const request = ({
  url,
  method,
  params,
  data,
}) => {
  const headers = {
    ...(localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN) && {
      Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)}`,
    }),
  };
  return axios({
    method,
    url,
    data,
    params,
    headers,
  })
    .catch((error) => {
      const res = error.response;
      const errorMessage = res ? res.data.message : 'Check your connection';

      message.error(errorMessage);
      throw new Error(errorMessage);
    });
};
