import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';

export const getHomeData = createAsyncThunk(
  '/home/getHomeData',
  async () => {
    const res = await request({ method: 'get', url: '/home' });
    return res.data;
  },
);
