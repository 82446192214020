import { dedicateSmile, thankYouForSmile, getSmileAmount } from '@redux/smile/thunks';

const thunksConfig = [
  {
    type: 'dedicateSmile',
    thunk: dedicateSmile,
  },
  {
    type: 'thankYouForSmile',
    thunk: thankYouForSmile,
  },
  {
    type: 'getSmileAmount',
    thunk: getSmileAmount,
    callbacks: {
      fulfilled: (state, action) => {
        state.amount = action.payload;
      },
    },
  },
];

export default thunksConfig;
