import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_ACTION_RESULT, SLICE_NAMES } from '@constants';
import { createCustomSelector } from '@utils';

export const initSlice = createSlice({
  name: SLICE_NAMES.INIT,
  initialState: {
    actionResult: DEFAULT_ACTION_RESULT,
    isReduxReady: true,
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.INIT].actionResult);

export default initSlice.reducer;
