import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAMES, DEFAULT_STATES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const homeSlice = createSlice({
  name: SLICE_NAMES.HOME,
  initialState: { ...DEFAULT_STATES.HOME },
  reducers: {},
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.HOME].actionResult);
export const homeDataSelector = createCustomSelector((state) => state[SLICE_NAMES.HOME].data);

export default homeSlice.reducer;
