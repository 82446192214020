import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAMES, DEFAULT_STATES, DEFAULT_ACTION_RESULT } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const advisorSlice = createSlice({
  name: SLICE_NAMES.ADVISOR,
  initialState: { ...DEFAULT_STATES.ADVISOR },
  reducers: {
    resetActionResult(state) {
      state.actionResult = DEFAULT_ACTION_RESULT;
    },
  },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.ADVISOR].actionResult);
export const symptomsSelector = createCustomSelector((state) => state[SLICE_NAMES.ADVISOR].symptoms);
export const symptomDetailsSelector = createCustomSelector((state) => state[SLICE_NAMES.ADVISOR].symptomDetails);
export const introSelector = createCustomSelector((state) => state[SLICE_NAMES.ADVISOR].intro);

export const {
  resetActionResult,
} = advisorSlice.actions;

export default advisorSlice.reducer;
