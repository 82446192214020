import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PATHS } from '@constants';

const AuthRouters = ({ component: Component, token, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!token) {
        return <Redirect to={PATHS.SIGN_IN} />;
      }

      return <Component {...props} />;
    }}
  />
);

export default AuthRouters;
