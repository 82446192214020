import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';

export const getMySpaceArticles = createAsyncThunk(
  'mySpace/getMySpaceArticles',
  async (params) => {
    const countriesResponse = await request({ method: 'get', url: '/my-space/info-room/articles', params });
    return countriesResponse.data;
  },
);

export const getMySpaceCollection = createAsyncThunk(
  'mySpace/getMySpaceCollection',
  async () => {
    const countriesResponse = await request({ method: 'get', url: '/my-space/us' });
    return countriesResponse.data;
  },
);

export const getMySpaceGallery = createAsyncThunk(
  'mySpace/getMySpaceGallery',
  async () => {
    const countriesResponse = await request({ method: 'get', url: '/my-space/sea-of-tranquility' });
    return countriesResponse.data;
  },
);

export const getMySpaceArticle = createAsyncThunk(
  'mySpace/getMySpaceArticle',
  async (articleId) => {
    const countriesResponse = await request({ method: 'get', url: `/my-space/info-room/articles/${articleId}` });
    return countriesResponse.data;
  },
);

export const getComments = createAsyncThunk(
  'mySpace/getComments',
  async (nodeId) => {
    const countriesResponse = await request({ method: 'get', url: `/comments/node/${nodeId}` });
    return countriesResponse.data;
  },
);

export const sendComment = createAsyncThunk(
  'mySpace/sendComment',
  async (data) => {
    const countriesResponse = await request({ method: 'post', url: `/comments/node/${data.id}`, data: { body: data.body } });
    return countriesResponse.data;
  },
);

export const toggleLike = createAsyncThunk(
  'mySpace/toggleLike',
  async (nodeId) => {
    const countriesResponse = await request({ method: 'post', url: `/likes/node/${nodeId}` });
    return countriesResponse.data;
  },
);

export const getArticlesCategories = createAsyncThunk(
  'dictionaries/getArticlesCategories',
  async () => {
    const diagnosisResponse = await request({ method: 'get', url: '/my-space/info-room/articles/categories' });
    return diagnosisResponse.data;
  },
);
