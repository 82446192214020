import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';

export const getNotifications = createAsyncThunk(
  '/notifications/getNotifications',
  async () => {
    const res = await request({ method: 'get', url: '/notifications' });
    return res.data;
  },
);

export const sendNotification = createAsyncThunk(
  '/notifications/sendNotification',
  async () => {
    const res = await request({ method: 'get', url: '/notifications' });
    return res.data;
  },
);

export const getNewNotifyCount = createAsyncThunk(
  '/notifications/getNewNotifyCount',
  async () => {
    const res = await request({ method: 'get', url: '/notifications/count/new' });
    return res.data;
  },
);
