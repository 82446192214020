import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_STATES, SLICE_NAMES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from '@redux/dictionaries/thunksConfig';

export const dictionariesSlice = createSlice({
  name: SLICE_NAMES.DICTIONARIES,
  initialState: { ...DEFAULT_STATES.DICTIONARIES },
  reducers: {
    resetCountries(state) {
      state.countries = [];
    },
    resetDiagnoses(state) {
      state.diagnoses = [];
    },
    resetTreatments(state) {
      state.treatments = [];
    },
    resetCurrentTreatment(state) {
      state.currentTreatment = null;
    },
    resetAgeGroups(state) {
      state.ageGroups = [];
    },
    clearDictionariesState: () => DEFAULT_STATES.DICTIONARIES,
  },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].actionResult);
export const countriesSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].countries);
export const diagnosesSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].diagnoses);
export const sideEffectTreatmentsSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].sideEffectTreatments);
export const currentTreatmentSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].currentTreatment);
export const ageGroupsSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].ageGroups);
export const conditionsSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].conditions);
export const clinicsSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].clinics);

export const {
  resetCountries,
  resetDiagnoses,
  resetTreatments,
  resetCurrentTreatment,
  resetAgeGroups,
  clearDictionariesState,
} = dictionariesSlice.actions;

export default dictionariesSlice.reducer;
