import {
  getMySpaceCollection,
  getMySpaceArticles,
  getMySpaceGallery,
  getMySpaceArticle,
  getComments,
  sendComment,
  toggleLike,
  getArticlesCategories,
} from '@redux/mySpace/thunks';
import { message } from 'antd';

const thunksConfig = [
  {
    type: 'getMySpaceArticles',
    thunk: getMySpaceArticles,
    callbacks: {
      fulfilled: (state, action) => {
        state.articles = action.payload;
      },
    },
  },
  {
    type: 'getMySpaceCollection',
    thunk: getMySpaceCollection,
    callbacks: {
      fulfilled: (state, action) => {
        state.collection = action.payload;
      },
    },
  },
  {
    type: 'getMySpaceGallery',
    thunk: getMySpaceGallery,
    callbacks: {
      fulfilled: (state, action) => {
        state.gallery = action.payload;
      },
    },
  },
  {
    type: 'getMySpaceArticle',
    thunk: getMySpaceArticle,
    callbacks: {
      fulfilled: (state, action) => {
        state.currentArticle = action.payload;
      },
    },
  },
  {
    type: 'getComments',
    thunk: getComments,
    callbacks: {
      fulfilled: (state, action) => {
        state.comments = action.payload;
      },
    },
  },
  {
    type: 'sendComment',
    thunk: sendComment,
    callbacks: {
      fulfilled: (state, action) => {
        const id = action.meta.arg.id;

        state.comments = [
          ...state.comments,
          {
            id: action.payload.id,
            body: action.payload.body,
            createdAt: action.payload.created_at,
            updatedAt: action.payload.updated_at,
            userUniqueId: '******',
          },
        ];

        state.collection = state.collection.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              commentsCount: item.commentsCount + 1,
            };
          }

          return item;
        });
      },
    },
  },
  {
    type: 'toggleLike',
    thunk: toggleLike,
    callbacks: {
      fulfilled: (state, action) => {
        const id = action.meta.arg;
        const status = action.payload.action;
        message.info(`You ${status} the post!`);

        state.collection = state.collection.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              likesCount: status === 'liked' ? item.likesCount + 1 : item.likesCount - 1,
            };
          }

          return item;
        });
      },
    },
  },
  {
    type: 'getArticlesCategories',
    thunk: getArticlesCategories,
    callbacks: {
      fulfilled: (state, action) => {
        state.categories = action.payload;
      },
    },
  },
];

export default thunksConfig;
