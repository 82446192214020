import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';

export const getSymptoms = createAsyncThunk(
  '/advisor/getSymptoms',
  async () => {
    const res = await request({ method: 'get', url: 'advisor/symptoms' });
    return res.data;
  },
);

export const getSymptomDetails = createAsyncThunk(
  '/advisor/getSymptomDetails',
  async (params) => {
    const res = await request({
      method: 'get', url: 'advisor/symptom-details', data: {}, params,
    });
    return res.data;
  },
);

export const getIntro = createAsyncThunk(
  '/advisor/getIntro',
  async () => {
    const res = await request({ method: 'get', url: 'advisor/introduction' });
    return res.data;
  },
);
