export const PATHS = {
  NOT_FOUND: '*',
  HOME: '/',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  FORGOT_PASS: '/forgot-password',
  CREATE_ACCOUNT: '/sign-up/create-account',
  MY_SPACE: '/my-space',
  MY_SPACE_INNER: (id = ':id') => `/my-space/${id}`,
  CLINICS: '/clinics',
  ADVISOR: '/advisor',
  ADVISOR_RECOMMENDATIONS: '/advisor-recommendations',
  STUDIES: '/studies',
  SURVEY: (id = ':id') => `/studies/${id}`,
  MAKE_SMILE: '/make-smile',
  DEDICATE: (id = ':id') => `/make-smile/${id}`,
  NOTIFICATIONS: '/notifications',
  TREATMENT: (id = ':id') => `/treatment/${id}`,
  TREATMENT_MEDICATION: (treatmentId = ':treatmentId', id = ':id') => `/treatment/${treatmentId}/${id}`,
  DIAGNOSE: (id = ':id') => `/diagnose/${id}`,
  PROFILE: '/my-profile',
  DASHBOARD: '/dashboard',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS: '/terms',
};
